import Badge from "@cloudscape-design/components/badge";
import SideNavigation from "@cloudscape-design/components/side-navigation";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export function SideNav(props: any) {

    const navigate = useNavigate();
    
    const [unreadNotifications, setUnreadNotifications] = useState( 0 );
    
    console.log(props.location);

    return (
        <SideNavigation 
          activeHref={props.activeHref} 
          header={{ href: "/" , text: "Supplychain" }} 
          onFollow={
              event=> { 
                  if (!event.detail.external) { 
                      event.preventDefault();
                    //   console.log("inside side nave pushing: " + event.detail.href);
                      props.setActiveHref(event.detail.href); 
                      navigate(event.detail.href, { replace: true });
                  } 
              }
          } 
          items={[ 
              { type: "link", text: "Active Workflows", href: "/active-workflows" }, 
              { type: "link", text: "Pending Approvals", href: "/pending-approvals" },
              { type: "link", text: "Completed Workflows", href: "/completed-workflows" },
              { type: "divider" }, 
              { type: "link", text: "Notifications", href: "/notifications", info: unreadNotifications > 0 ? <Badge color="red">{ unreadNotifications }</Badge> : "" }
          ]} 
      />
    )
}