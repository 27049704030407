import Alert from "@cloudscape-design/components/alert";
import BreadcrumbGroup from "@cloudscape-design/components/breadcrumb-group";
import Button from "@cloudscape-design/components/button";
import Container from "@cloudscape-design/components/container";
import ContentLayout from "@cloudscape-design/components/content-layout";
import Header from "@cloudscape-design/components/header";
import Link from "@cloudscape-design/components/link";
import SpaceBetween from "@cloudscape-design/components/space-between";

export function Notifications() {
    return (
        <ContentLayout header={ 
            <SpaceBetween size="m">
                <BreadcrumbGroup 
                    items={
                        [        
                            { text: "System", href: "#" },
                            { text: "Components", href: "#components" },        
                            { text: "Breadcrumb group", href: "#components/breadcrumb-group" }
                        ]
                    }      
                    ariaLabel="Breadcrumbs"    
                />
                <Header variant="h1" info={<Link>Info</Link>} description="Supplychain events will show up on this page.">
                    Notifications
                </Header>
                <Alert>This is a generic alert.</Alert>
            </SpaceBetween> 
        }>
            <Container header={ <Header variant="h2" description="Container description">Container header</Header> }>
                Container content
            </Container>
        </ContentLayout>
    )
}