import Box from "@cloudscape-design/components/box";
import BreadcrumbGroup from "@cloudscape-design/components/breadcrumb-group";
import Button from "@cloudscape-design/components/button";
import ColumnLayout from "@cloudscape-design/components/column-layout";
import Container from "@cloudscape-design/components/container";
import ContentLayout from "@cloudscape-design/components/content-layout";
import ExpandableSection from "@cloudscape-design/components/expandable-section";
import Header from "@cloudscape-design/components/header";
import Link from "@cloudscape-design/components/link";
import Popover from "@cloudscape-design/components/popover";
import SpaceBetween from "@cloudscape-design/components/space-between";
import StatusIndicator from "@cloudscape-design/components/status-indicator";
import Table from "@cloudscape-design/components/table";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export function ActiveWorkflow(props: any) {
    const { workflowId } = useParams(); 
    const [activeWorkflowId, setActiveWorkflowId] = useState<any>("");
    const [order, setOrder] = useState<any>({});
    const [orderUpdateComponents, setOrderUpdateComponents] = useState<any>([]);

    const ValueWithLabel = ({ label, children }: any) => (
        <div>
          <Box variant="awsui-key-label">{label}</Box>
          <div>{children}</div>
        </div>
    );
    
    function getWorkflowFromDb(workflowId: string) {
        let auth_key = "" 
        if (localStorage.getItem('auth-key') !== null) {
            auth_key = JSON.parse(JSON.stringify(localStorage.getItem('auth-key')))
        }
        return fetch(`https://wtwz8w9vdj.execute-api.us-west-2.amazonaws.com/Prod/workflows/${workflowId}`, {
            method: 'GET', 
            headers: new Headers ({
                'Authorization': auth_key
            })
        })
        .then(res => res.json())
    }

    function getInprogressIndicator() {
        return <StatusIndicator type="in-progress">In progress</StatusIndicator>;
    }
    
    function getSuccessIndicator() {
        return <StatusIndicator>Success</StatusIndicator>;
    }
    
    function getErrorIndicator() {
        return <StatusIndicator type="error">Error</StatusIndicator>
    }

    function buildOrderUpdateComponents(order: any) {
        let sources:any = [];
        
        console.log(`orderUpdates is a: ${typeof(order)}`);
        console.log(JSON.stringify(order));
        if (order) {
            if (order.hasOwnProperty('OrderEvents')) {
                if (Object.keys(order.OrderEvents).length > 0) {
                    
                    Object.keys(order.OrderEvents).forEach((orderEventSource: any) => {
            
                        var orderSourceEvents = order.OrderEvents[orderEventSource];

                        // build inner components first, add them incrementally then push to main collection.
                
                        // service - expandable 
                        const incomingOrderEventsHeader = <div><span>Incoming Order Events:</span></div>
                        
                        const orderSourceEventsSection = <ExpandableSection defaultExpanded headerText={incomingOrderEventsHeader}>
                            <Table columnDefinitions={[
                                    {
                                        id: "message",
                                        header: "Message",
                                        cell: (item: any) => item.DetailType || "-",
                                        sortingField: "message"
                                    },
                                    {
                                        id: "status",
                                        header: "Status",
                                        cell: (item: any) => item.Status === 'complete' || item.Status === 'success' ? getSuccessIndicator() : item.Status === 'inProgress' ? getInprogressIndicator() : getErrorIndicator() || "-",
                                        sortingField: "alt"
                                    },
                                    {
                                        id: "eventTime",
                                        header: "Event Time",
                                        cell: (item: any) => item.WhenEventHappened || "-"
                                    }
                                ]}
                                items={orderSourceEvents} 
                                loading={false}
                                loadingText="Loading resources"
                                sortingDisabled
                                variant="embedded"
                                empty={
                                    <Box textAlign="center" color="inherit">
                                    <b>No messages</b>
                                    <Box
                                        padding={{ bottom: "s" }}
                                        variant="p"
                                        color="inherit"
                                    >
                                        No status messages to display.
                                    </Box>
                                    </Box>
                                }
                            />
                        </ExpandableSection>
                
                        const sourceSection = <Container>
                            <SpaceBetween size="m">
                            <h2>{ orderEventSource }</h2>
                            { orderSourceEventsSection }
                            </SpaceBetween>
                        </Container>

                        sources.push(sourceSection);
                    });
                }
            }
        }
    
        return sources;
      }

    function onLoad() {
        const currentWorkflowId = JSON.parse(JSON.stringify(workflowId));
        setActiveWorkflowId(currentWorkflowId);
        console.log("Current Order ID");
        console.log(currentWorkflowId);
        getWorkflowFromDb(currentWorkflowId).then(
          (result) => {
            console.log(result);
            setOrder(result);
          },
          (error) => {
            // handle errors.
            console.log(error);
          }
        )

        setInterval(
            (() => {getWorkflowFromDb(currentWorkflowId).then(
                (result: any) => {
                    console.log(result);
                    console.log('Setting order updates!');
                    setOrder(result);
                    setOrderUpdateComponents(buildOrderUpdateComponents(result));
                    console.log(orderUpdateComponents);
                },
                (error: any) => {
                    // handle errors.
                    console.log(error);
                    localStorage.removeItem('auth-key');
                }
            )}), 2000
        )
    }
    
    useEffect(onLoad, []);

    return (
        <ContentLayout header={ 
            <SpaceBetween size="m">
                <BreadcrumbGroup 
                    items={
                        [        
                            { text: "Home", href: "/" },
                            { text: "Active Workflows", href: "/active-workflows" },        
                            { text: order.DealTitle, href: `/active-workflows/${activeWorkflowId}` }
                        ]
                    }      
                    ariaLabel="Breadcrumbs"    
                />
                <Header variant="h1" info={<Link>Info</Link>} description="Detail and live status of currently active workflow">
                    Active Workflow Detail
                </Header>
                {/* <Alert>This is a generic alert.</Alert> */}
            </SpaceBetween> 
        }>
            <SpaceBetween size="l">
                <Container header={ <Header variant="h2" description="Live status/progress for this workflow will show below">Detail for Title: {order.DealTitle}</Header> }>
                    <ColumnLayout columns={3} variant="text-grid">
                        <SpaceBetween size="l">
                            <ValueWithLabel label="Workflow Type">{ order.WorkflowType }</ValueWithLabel>
                            <ValueWithLabel label="Order Vendor">{ order.OrderVendor }</ValueWithLabel>
                            <ValueWithLabel label="Workflow Status">{ order.WorkflowStatus }</ValueWithLabel>
                        </SpaceBetween>
                        <SpaceBetween size="l">
                            <ValueWithLabel label="Workflow ID">
                                <div className="custom-wrapping">
                                    <Box margin={{ right: 'xxs' }} display="inline-block">
                                    <Popover
                                        size="small"
                                        position="top"
                                        dismissButton={false}
                                        triggerType="custom"
                                        content={<StatusIndicator type="success">Workflow ID copied</StatusIndicator>}
                                    >
                                        <Button variant="inline-icon" iconName="copy" ariaLabel="Copy Workflow ID" onClick={() => {navigator.clipboard.writeText(order.Attribute1)}} />
                                    </Popover>
                                    </Box>
                                    { order.Attribute1 }
                                </div>
                            </ValueWithLabel>
                            <ValueWithLabel label="Sub Count">{ order.SubLanguageCount }</ValueWithLabel>
                            <ValueWithLabel label="Dub Count">{ order.DubLanguageCount }</ValueWithLabel>
                        </SpaceBetween>
                        <SpaceBetween size="l">
                            <ValueWithLabel label="Deal Start Date">{ order.DealStartDate }</ValueWithLabel>
                            <ValueWithLabel label="Deal End Date">{ order.DealEndDate }</ValueWithLabel>
                            <ValueWithLabel label="Order Cost Estimate">{ <Box color="text-status-success" variant="code">{order.OrderCostEstimate} - Approved</Box> }</ValueWithLabel>
                        </SpaceBetween>
                    </ColumnLayout>
                </Container>
                { orderUpdateComponents }
            </SpaceBetween>
        </ContentLayout>
    )
}