import Box from "@cloudscape-design/components/box";
import BreadcrumbGroup from "@cloudscape-design/components/breadcrumb-group";
import Container from "@cloudscape-design/components/container";
import ContentLayout from "@cloudscape-design/components/content-layout";
import Header from "@cloudscape-design/components/header";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Table from "@cloudscape-design/components/table";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export function ActiveWorkflows() {
    const [workflows, setWorkflows] = useState<any>([])

    function getWorkflowsFromDb() {
        let auth_key = "" 
        if (localStorage.getItem('auth-key') !== null) {
            auth_key = JSON.parse(JSON.stringify(localStorage.getItem('auth-key')))
        }
        return fetch(`https://wtwz8w9vdj.execute-api.us-west-2.amazonaws.com/Prod/workflows`, {
            method: 'GET', 
            headers: new Headers ({
                'Authorization': auth_key
            })
        })
        .then(res => res.json())
    }

    function onLoad() {
        getWorkflowsFromDb().then(
          (result) => {
            console.log(result);
            setWorkflows(result);
          },
          (error) => {
            // handle errors.
            console.log(error);
          }
        )
    }
    
    useEffect(onLoad, []);

    return (
        <ContentLayout header={ 
            <SpaceBetween size="m">
                <BreadcrumbGroup 
                    items={
                        [        
                            { text: "Home", href: "/" },
                            { text: "Active Workflows", href: "#" }
                        ]
                    }      
                    ariaLabel="Breadcrumbs"    
                />
                <Header variant="h1" description="Workflows that are in-progress and receiving updates.">
                    Active Workflows
                </Header>
            </SpaceBetween> 
        }>
            <Container header={ <Header variant="h2" description="Select one of the active workflows below to see more detail">Currently Active Workflows</Header> }>
            <Table
                columnDefinitions={[
                    {
                        id: "DealTitle",
                        header: "Deal Title",
                        cell: item => <Link to={'/active-workflows/' + item.Attribute1}>{item.DealTitle}</Link> || "-",
                        sortingField: "DealTitle"
                    },
                    {
                        id: "workflowType",
                        header: "Workflow Type",
                        cell: item => item.WorkflowType || "-",
                        sortingField: "workflowType"
                    },
                    {
                        id: "TitleCount",
                        header: "Total Titles",
                        cell: item => item.TitleCount || "-"
                    },
                    {
                        id: "WorkflowStatus",
                        header: "Status",
                        cell: item => item.WorkflowStatus || "-"
                    },
                    {
                        id: "DubLanguageCount",
                        header: "Dub Languages",
                        cell: item => item.DubLanguageCount || "-"
                    },
                    {
                        id: "SubLanguageCount",
                        header: "Sub Languages",
                        cell: item => item.SubLanguageCount || "-"
                    }
                ]}
                items={workflows as any[]}
                // items={[
                //     {
                //         workflowId: "e77421f8-92e0-4262-86ac-9543a38b132c",
                //         workflowType: "Television Program",
                //         totalTitles: 3,
                //         status: "in-progress",
                //         dubLanguageCount: 5,
                //         subLanguageCount: 13
                //     },
                //     {
                //         workflowId: "83c4a71c-af70-4105-8afc-41f32d3b406b",
                //         workflowType: "Radio",
                //         totalTitles: 1,
                //         status: "in-progress",
                //         dubLanguageCount: 2,
                //         subLanguageCount: 7
                //     },
                //     {
                //         workflowId: "7f3794f6-a13b-4599-905c-af7f90e94a19",
                //         workflowType: "Broadcast",
                //         totalTitles: 5,
                //         status: "in-progress",
                //         dubLanguageCount: 7,
                //         subLanguageCount: 10
                //     },
                //     {
                //         workflowId: "035b50f2-49cb-4e3c-848a-8460cfe0b49d",
                //         workflowType: "Television Program",
                //         totalTitles: 2,
                //         status: "in-progress",
                //         dubLanguageCount: 3,
                //         subLanguageCount: 7
                //     }
                // ]}
                loadingText="Loading resources"
                stripedRows
                variant="embedded"
                empty={
                    <Box textAlign="center" color="inherit">
                    <b>No resources</b>
                    <Box
                        padding={{ bottom: "s" }}
                        variant="p"
                        color="inherit"
                    >
                        No resources to display.
                    </Box>
                    </Box>
                }
                />
            </Container>
        </ContentLayout>
    )
}