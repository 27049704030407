import Alert from "@cloudscape-design/components/alert";
import Box from "@cloudscape-design/components/box";
import BreadcrumbGroup from "@cloudscape-design/components/breadcrumb-group";
import Button from "@cloudscape-design/components/button";
import ColumnLayout from "@cloudscape-design/components/column-layout";
import Container from "@cloudscape-design/components/container";
import ContentLayout from "@cloudscape-design/components/content-layout";
import FormField from "@cloudscape-design/components/form-field";
import Header from "@cloudscape-design/components/header";
import Link from "@cloudscape-design/components/link";
import Multiselect from "@cloudscape-design/components/multiselect";
import Popover from "@cloudscape-design/components/popover";
import Select from "@cloudscape-design/components/select";
import SpaceBetween from "@cloudscape-design/components/space-between";
import StatusIndicator from "@cloudscape-design/components/status-indicator";
import Table from "@cloudscape-design/components/table";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import './ApproveOrder.css';

export function ApproveOrder(props: any) {
    const { workflowId } = useParams(); 

    const [shouldShowApproveSuccess, setShouldShowApproveSuccess] = useState(false);
    const [order, setOrder] = useState<any>({});
    const [orderAssets, setOrderAssets] = useState<any>([]);
    const [selectedMixTypes, setSelectedMixTypes] = useState<any>([{"label": "2.0", "value": "2.0"}, {"label": "5.1", "value": "5.1"}, {"label": "7.1", "value": "7.1"}]);
    const availableMixTypes: any = [{"label": "2.0", "value": "2.0"}, {"label": "5.1", "value": "5.1"}, {"label": "7.1", "value": "7.1"}, {"label": "ATMOS", "value": "ATMOS"}]
    const [selectedSLA, setSelectedSLA] = useState<any>({"label": "Standard", "value": "Standard"});
    const availableSLAs: any = [{"label": "Standard", "value": "Standard"}, {"label": "Standard with Approvals", "value": "Standard with Approvals"}, {"label": "Complex", "value": "Complex"}]
    const [selectedLangAttrib, setSelectedLangAttrib] = useState<any>({"label": "Full", "value": "full"});
    const availableLangAttribs: any = [{"label": "Full", "value": "full"},{"label": "Forced", "value": "forced"},{"label": "SDH", "value": "sdh"}]
    
    const ValueWithLabel = ({ label, children }: any) => (
        <div>
          <Box variant="awsui-key-label">{label}</Box>
          <div>{children}</div>
        </div>
    );

    function getOrderTitles(orderObj: any) {
        let titles:any = []
        // for (let i = 0; i < orderObj.titles.length; i++)
        // {
        //   let title = orderObj.titles[i];
    
        //   titles.push(
        //       <Container>
        //       <SpaceBetween size="m">
        //         <FormField
        //           description="Name of the Title Associated with the Order"
        //           label="Title"
        //           >
        //           <Input
        //               value={title.name}
        //               placeholder="Order title description."
        //               readOnly={true}
        //           />
        //         </FormField>
        //         <FormField
        //           description="This Title's EIDR ID"
        //           label="EIDR ID"
        //           >
        //           <Input
        //               value={title.eidrId}
        //               placeholder="EIDR ID"
        //               readOnly={true}
        //           />
        //         </FormField>
        //         <FormField
        //           description="This Title's Country ISO"
        //           label="Country ISO"
        //           >
        //           <Input
        //               value={title.countryIso}
        //               placeholder="Country ISO"
        //               readOnly={true}
        //           />
        //         </FormField>
        //         <FormField
        //           description="This Title's Locale"
        //           label="Locale"
        //           >
        //           <Input
        //               value={title.locale}
        //               placeholder="Locale"
        //               readOnly={true}
        //           />
        //         </FormField>
        //         <FormField
        //           description="This Title's Content Type"
        //           label="Content Type"
        //           >
        //           <Input
        //               value={title.contentType}
        //               placeholder="Content Type"
        //               readOnly={true}
        //           />
        //         </FormField>
        //       </SpaceBetween>
        //     </Container>
        //   );
        // }
    
        return titles;
    }

    function getWorkflowFromDb(workflowId: string) {
        let auth_key = "" 
        if (localStorage.getItem('auth-key') !== null) {
            auth_key = JSON.parse(JSON.stringify(localStorage.getItem('auth-key')))
        }
        return fetch(`https://wtwz8w9vdj.execute-api.us-west-2.amazonaws.com/Prod/workflows/${workflowId}`, {
            method: 'GET', 
            headers: new Headers ({
                'Authorization': auth_key
            })
        })
        .then(res => res.json())
    }

    function submitOrder() {
        // const payload = JSON.parse(JSON.stringify(order));

        var fullWorkflowObj = JSON.parse(order.OriginalEventInfo);
        fullWorkflowObj.attributes.workflowStatus = "in-progress";
        console.log("FULL APPROVED WORKFLOW INFO");
        console.log(fullWorkflowObj);
        
        fetch(`https://wtwz8w9vdj.execute-api.us-west-2.amazonaws.com/Prod/workflows/${workflowId}`, {
          method: 'POST', 
          cache: 'no-cache',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('auth-key')}`
          },
          body: JSON.stringify(fullWorkflowObj)
        })
          .then(res => res.json())
          .then(
            (result) => {
              console.log(result);
              setShouldShowApproveSuccess(true);
              setTimeout(() => {window.location.href = `/active-workflows/${workflowId}`;}, 2000);
            },
            (error) => {
              // handle errors.
              console.log(error);
              // localStorage.removeItem('auth-key');
            }
          )
        // window.location.href = '/new-job';
    }

    function cancelOrder() {
        window.location.href = '/pending-approvals';
    }

    function onLoad() {
        const currentWorkflowId = JSON.parse(JSON.stringify(workflowId));
        console.log("Current Order ID");
        console.log(currentWorkflowId);
        getWorkflowFromDb(currentWorkflowId).then(
          (result) => {
            console.log(result);
            setOrder(result);

            var ord = JSON.parse(result.OriginalEventInfo);
            // console.log("ORIG ORDER");
            // console.log(ord);
            // console.log("ASSETS");
            // console.log(ord.assets);

            // 
            //  This section is to add the "missing" attribute to the assets objects.
            //      I also added some logic to randomly make the assets "missing"
            //      until we find a good solution for laying out the assets table.
            //            
            ord.assets.forEach((asset: any, i: any) => {
                asset.missing = false;
                console.log(asset);
            });

            if (ord.assets.length > 0) {
                ord.assets[ord.assets.length - 1].missing = true;
            }

            if (ord.assets.length > 2) {
                for (var i = 1; i < Math.floor(Math.random() * ord.assets.length); i++) {
                    ord.assets[Math.floor(Math.random() * ord.assets.length)].missing = true;
                }
            }

            console.log("TYPEOF")
            console.log(typeof ord.assets);
            console.log(ord.assets.length);

            ord.assetCheck.missingAssetTypes.forEach((asset: any, i: any) => {
                ord.assets.push({
                    "filename": "",
                    "filepath": "",
                    "tag": asset,
                    "missing": true
                  });
            });            

            setOrderAssets(ord.assets);
          },
          (error) => {
            // handle errors.
            console.log(error);
          }
        )
    }

    useEffect(onLoad, []);

    return (
        <ContentLayout header={ 
            <SpaceBetween size="m">
                <BreadcrumbGroup 
                    items={
                        [        
                            { text: "Home", href: "" },
                            { text: "Pending Approvals", href: "/pending-approvals" },        
                            { text: "Approve Order", href: "#" }
                        ]
                    }      
                    ariaLabel="Breadcrumbs"    
                />
                <Header variant="h1" info={<Link>Info</Link>} description="Please review the following order details and submit if costs are approved for localization." actions={
                    <SpaceBetween direction="horizontal" size="s">
                        <Button onClick={cancelOrder}>Cancel</Button>
                        <Button onClick={submitOrder} variant="primary">
                        Approve Order
                        </Button>
                    </SpaceBetween>
                }>
                    Approve Order
                </Header>
                {
                    shouldShowApproveSuccess ? 
                        <Alert dismissAriaLabel="Close alert" dismissible statusIconAriaLabel="Success" type="success">The Order has been Approved!</Alert>
                    : ""
                }
            </SpaceBetween> 
        }>
            <SpaceBetween size="m">
                <Container header={ <Header variant="h2" description="Existing details about order">Order Details ({order.DealTitle})</Header> }>
                    <ColumnLayout columns={3} variant="text-grid">
                        <SpaceBetween size="l">
                            <ValueWithLabel label="Workflow Type">{ order.WorkflowType }</ValueWithLabel>
                            <ValueWithLabel label="Order Vendor">{ order.OrderVendor }</ValueWithLabel>
                            <ValueWithLabel label="Workflow Status">{ order.WorkflowStatus }</ValueWithLabel>
                        </SpaceBetween>
                        <SpaceBetween size="l">
                            <ValueWithLabel label="Workflow ID">
                                <div className="custom-wrapping">
                                    <Box margin={{ right: 'xxs' }} display="inline-block">
                                    <Popover
                                        size="small"
                                        position="top"
                                        dismissButton={false}
                                        triggerType="custom"
                                        content={<StatusIndicator type="success">Workflow ID copied</StatusIndicator>}
                                    >
                                        <Button variant="inline-icon" iconName="copy" ariaLabel="Copy Workflow ID" onClick={() => {navigator.clipboard.writeText(order.Attribute1)}} />
                                    </Popover>
                                    </Box>
                                    { order.Attribute1 }
                                </div>
                            </ValueWithLabel>
                            <ValueWithLabel label="Sub Count">{ order.SubLanguageCount }</ValueWithLabel>
                            <ValueWithLabel label="Dub Count">{ order.DubLanguageCount }</ValueWithLabel>
                        </SpaceBetween>
                        <SpaceBetween size="l">
                            <ValueWithLabel label="Deal Start Date">{ order.DealStartDate }</ValueWithLabel>
                            <ValueWithLabel label="Deal End Date">{ order.DealEndDate }</ValueWithLabel>
                            <ValueWithLabel label="Order Cost Estimate">{ <Box color="text-status-error" variant="code">{order.OrderCostEstimate}</Box> }</ValueWithLabel>
                        </SpaceBetween>
                    </ColumnLayout>
                </Container>
                <Container>
                    <SpaceBetween size="m">
                        <h3 className="awsui_label_14mhv_yshzd_180">Please select mix types:</h3>
                        <Multiselect
                        selectedOptions={selectedMixTypes}
                        onChange={({ detail }) =>
                            setSelectedMixTypes(detail.selectedOptions)
                        }
                        deselectAriaLabel={e => `Remove ${e.label}`}
                        options={availableMixTypes}
                        placeholder="Choose mix types"
                        selectedAriaLabel="Selected"
                        />
                        <FormField
                            description="Please choose an SLA."
                            label="SLA"
                            >
                            <Select
                                    selectedOption={selectedSLA}
                                    onChange={({ detail }) =>
                                    setSelectedSLA(detail.selectedOption)
                                    }
                                    options={availableSLAs}
                                    selectedAriaLabel="Selected"
                            />
                        </FormField>
                        
                        <FormField
                            description="Please choose a Language Attribute."
                            label="Language Attribute"
                            >
                            <Select
                                    selectedOption={selectedLangAttrib}
                                    onChange={({ detail }) =>
                                    setSelectedLangAttrib(detail.selectedOption)
                                    }
                                    options={availableLangAttribs}
                                    selectedAriaLabel="Selected"
                            />
                        </FormField>
                    </SpaceBetween>
                </Container>
                {
                    getOrderTitles(order)
                }
                <Container>
                    <SpaceBetween size="m">
                        <Table
                            columnDefinitions={[
                                {
                                    id: "filename",
                                    header: "File Name",
                                    cell: (item: any) => item.filename,
                                    sortingField: "filename"
                                },
                                {
                                    id: "tag",
                                    header: "Type",
                                    cell: (item: any) => item.tag || "-",
                                    sortingField: "tag"
                                },
                                {
                                    id: "state",
                                    header: "State",
                                    cell: (item: any) => item.missing ? <StatusIndicator type="error">Missing</StatusIndicator> : <StatusIndicator>Available</StatusIndicator> || "-"
                                }
                            ]}
                            items={orderAssets}
                            loadingText="Loading resources"
                            stickyHeader
                            stripedRows
                            variant="embedded"
                            wrapLines
                            empty={
                                <Box textAlign="center" color="inherit">
                                    <b>No resources</b>
                                    <Box
                                        padding={{ bottom: "s" }}
                                        variant="p"
                                        color="inherit"
                                    >
                                        No resources to display.
                                    </Box>
                                </Box>
                            }
                            // header={<Header description={<div><Icon className="red-flag" name="flag" /> <span className="red-flag">indicates required</span></div>}>Assets</Header>}
                            header={<Header description="Asset information will be shown below">Assets</Header>}
                            />
                    </SpaceBetween>
                </Container>
            </SpaceBetween>
        </ContentLayout>
    )
}